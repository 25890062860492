import React from 'react';

function FooterComponent(props) {
    return (
        <footer className="page-footer font-small mt-5 pt-5 bottom">
            <div className="container-fluid text-center">
                <div className="row">
                    <div className="col-xs-12 col-sm-12">
                        <h5 className="text-uppercase font-weight-bold text-center text_black">Need Help?</h5>
                        <p className="text-center text_black">Contact Our Customer Support Team</p>
                        <p className="text-center text_black"><a className="link_color" href="https://www.mediafiche.com/faq" target="_blank" rel="noreferrer">FAQ</a>  |  <a href="https://www.mediafiche.com" className="link_color" target="_blank" rel="noreferrer">Toll-Free Line: 866.514.3342</a> |  Email: support@mediafiche.com  |  <a href="https://www.mediafiche.com/privacy-policy" className="link_color" target="_blank" rel="noreferrer">Privacy Policy </a></p>
                    </div>
                </div>
            </div>

            <div className="footer-copyright text-center py-3 text_black">
                <div className="text_black">
                    © 2020 Copyright:
      <a target="_blank" rel="noreferrer" className="link_color" href="https://mediafiche.com/"> Mediafiche.com</a>
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;