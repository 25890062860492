import React, { Component } from 'react'
import { AuthConsumer } from '../Service/authProvider';


export default class SignInComponent extends Component {
    render() {

        return (
            <AuthConsumer>
                {
                    (p) => {
                        p.login();
                        
                        return <div>loading...</div>
                    }
                }
            </AuthConsumer>
        )
    }
}