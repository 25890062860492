import React, { Component } from 'react'
import { AuthConsumer } from "../Service/authProvider";


export default class SignInCallbackComponent extends Component {
    render() {
        return (
            <AuthConsumer>
                {
                    (p) => {
                        p.completeAuthentication().then(x => {
                            window.location.replace('/');
                        });

                        return <div>loading...</div>;
                    }
                }
            </AuthConsumer>
        )
    }
}