import React, { Component } from 'react'
import { AuthConsumer } from '../Service/authProvider';

export default class SignOutCallbackComponent extends Component {

    render() {
        return (
            <AuthConsumer>
                {
                    (p)=>{
                        p.signout();
                        
                        return <div>loading...</div>
                    }
                }
            </AuthConsumer>
        )
    }
}