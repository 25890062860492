
import React, { Component } from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent'
import magazine from '../Images/magazine.png'
import newspaper from '../Images/newspaper.png'
import Billboard from '../Images/billboard.png'
import { MDBAnimation } from "mdbreact";
import magazineIcon from '../Images/magazine icon.png'

import newspaperIcon from '../Images/newspaper icon.png'

import billboardIcon from '../Images/billboard icon.png'
import { withRouter } from 'react-router-dom';

import tvIcon from '../Images/icons8-retro-tv-100.png'

import { AuthConsumer } from '../Service/authProvider';

const { REACT_APP_MAGAZINE_LOGIN_URL, REACT_APP_MAGAZINE_SEARCH_BROWSE_URL, REACT_APP_MAGAZINE_OFFER_DASHBOARD_URL } = process.env

export class MainContent extends Component {
    nextPath(path) {
        this.props.history.push(path);
    }

    render() {
        return (
            <div className="container-fluid">
                <HeaderComponent></HeaderComponent>
                <div className="row justify-content-center no-gutters pt-5 mt-3" >
                    <div className="col-sm-12 col-md-12">
                        <div id="carouselExampleIndicators" className="carousel slide top-to-bottom" data-ride="carousel">
                            <ol className="carousel-indicators" style={{ color: "#FF4500" }}>
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                            </ol>
                            <div className="carousel-inner" id="my_text_slider">
                                <div className="carousel-item active">
                                    <img className="d-block w-100 img-bg" src={magazine} alt="First slide" />
                                    <div className="mask" style={{ backgroundColor: "rgba(0, 15, 85, 0.5)" }}>
                                        <div className="carousel-caption d-none d-md-block">
                                            <div className="text-center white-text mx-5 fadeIn animated carousel-text">
                                                <MDBAnimation type="slideInUp">

                                                    <h1 className="mb-4">
                                                        <strong className="title">Welcome to Mediafiche</strong>
                                                    </h1>

                                                    <p>
                                                        <strong className="subtitle">Log in to begin accessing the most comprehensive online media buying platform.</strong>
                                                    </p>
                                                </MDBAnimation>
                                            </div></div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img className="d-block w-100 img-bg" src={newspaper} alt="Second slide" />
                                    <div className="mask" style={{ backgroundColor: "rgba(0, 15, 85, 0.5)" }}>
                                        <div className="carousel-caption d-none d-md-block">
                                            <div className="text-center white-text mx-5 fadeIn animated carousel-text"
                                            >
                                                <MDBAnimation type="slideInUp">
                                                    <h1 className="mb-4">
                                                        <strong className="title">Welcome to Mediafiche</strong>
                                                    </h1>

                                                    <p>
                                                        <strong className="subtitle"> Log in to begin accessing the most comprehensive online media buying platform. </strong>
                                                    </p>
                                                </MDBAnimation>
                                            </div></div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <img className="d-block w-100 img-bg" src={Billboard} alt="Third slide" />
                                    <div className="mask" style={{ backgroundColor: "rgba(0, 15, 85, 0.5)" }}>
                                        <div className="carousel-caption d-none d-md-block">
                                            <div className="text-center white-text mx-5 fadeIn animated carousel-text"
                                            >
                                                <MDBAnimation type="slideInUp">
                                                    <h1 className="mb-4">
                                                        <strong className="title">Welcome to Mediafiche</strong>
                                                    </h1>

                                                    <p>
                                                        <strong className="subtitle"> Log in to begin accessing the most comprehensive online media buying platform. </strong>
                                                    </p>
                                                </MDBAnimation>
                                            </div></div>
                                    </div>
                                </div>
                            </div>
                            <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                    <section className="section section-lg section-top-1 bg-gray-4 mt-3">
                        <div className="container section-offeset">
                            <div className="box-categories cta-box-wrap">
                                <div className="box-categories-content">
                                    <div className="row justify-content-center">
                                        <div className="col-md-4 fadeInDown col-9 animation-img ">
                                            <div className="card list-marked-2 box-categories-list">
                                                <div className="card-body text-center">
                                                    <h5 className="card-title mb-5 tittle1">Magazines</h5>
                                                    <a href="/"><img className="card-img-top img_width mb-5" src={magazineIcon} alt="" /></a>
                                                    <p className="card-text pb-4">Target over 5,000 magazine titles</p>


                                                    <AuthConsumer>
                                                        {
                                                            (p) => {
                                                                return (
                                                                    p.isAuthenticated() === false ?
                                                                        <a href={REACT_APP_MAGAZINE_LOGIN_URL} className="card-link"><button type="button"
                                                                            className="btn mf-btn border-radius btn-md waves-light">Magazine</button>
                                                                        </a>
                                                                        : ''
                                                                )
                                                            }
                                                        }
                                                    </AuthConsumer>

                                                    <AuthConsumer>
                                                        {
                                                            (p) => {
                                                                return (
                                                                    p.isAuthenticated() === true && p.isBuyer() === true ?
                                                                        <a href={REACT_APP_MAGAZINE_SEARCH_BROWSE_URL} className="card-link"><button type="button"
                                                                            className="btn mf-btn border-radius btn-md waves-light">Search & Browse</button>
                                                                        </a>
                                                                        : ''
                                                                )
                                                            }
                                                        }
                                                    </AuthConsumer>


                                                    <AuthConsumer>
                                                        {
                                                            (p) => {
                                                                return (
                                                                    p.isAuthenticated() === true && p.isSeller() === true ?
                                                                        <a href={REACT_APP_MAGAZINE_OFFER_DASHBOARD_URL} className="card-link"><button type="button"
                                                                            className="btn mf-btn border-radius btn-md waves-light">Offer Dashboard</button>
                                                                        </a>
                                                                        : ''
                                                                )
                                                            }
                                                        }
                                                    </AuthConsumer>



                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="section section-lg section-top-1 bg-gray-4 mt-3">
                        <div className="container section-offeset">
                            <div className="box-categories cta-box-wrap">
                                <div className="box-categories-content">
                                    <div className="row justify-content-center">
                                        <div className="col-md-4 fadeInDown col-9 animation-img">
                                            <div className="card list-marked-2 box-categories-list">
                                                <div className="card-body text-center">
                                                    <h5 className="card-title mb-5 tittle1">Tv</h5>
                                                    <a href="/">
                                                        <img className="card-img-top img_width mb-5" src={tvIcon} alt="" />
                                                    </a>
                                                    <p className="card-text pb-4">Contact Us to advertise for television</p>
                                                    <a className="card-link"><button type="button"
                                                        className="btn mf-btn border-radius btn-md waves-light" onClick={() => this.nextPath('/tv')}>Contact Us</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="col-md-4 fadeInDown col-9 animation-img">
                                            <div className="card list-marked-2 box-categories-list">
                                                <div className="card-body text-center">
                                                    <h5 className="card-title mb-5 tittle1">Newspaper</h5>
                                                    <a href="/">
                                                        <img className="card-img-top img_width mb-5" src={newspaperIcon} alt="" />
                                                    </a>
                                                    <p className="card-text pb-4">Contact Us to advertise for Newspaper</p>
                                                    <a className="card-link"><button type="button"
                                                        className="btn mf-btn border-radius btn-md waves-light" onClick={() => this.nextPath('/newspaper')}>Contact Us</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="col-md-4 fadeInDown col-9 animation-img">
                                            <div className="card list-marked-2 box-categories-list">
                                                <div className="card-body text-center">

                                                    <h5 className="card-title mb-5 tittle1">OOH</h5>
                                                    <a href="/"><img className="card-img-top img_width mb-5" src={billboardIcon} alt="" /></a>
                                                    <p className="card-text">Target over 440,000 digital outdoor screens </p>
                                                    <a className="card-link"><button type="button"
                                                        className="btn mf-btn border-radius btn-md waves-light" onClick={() => this.nextPath('/dooh')}>Contact Us</button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div >
                <FooterComponent></FooterComponent>
            </div >
        )
    }
}

