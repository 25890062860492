import React, { Component } from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import { MDBInput, MDBFormInline } from 'mdbreact';

class NewsPaper extends Component {
    render() {
        return (
            <div className="container-fluid">
                <HeaderComponent></HeaderComponent>
                <div className="row justify-content-center no-gutters pt-5 mt-3" >
                    <div className="col-sm-9 col-md-9">
                        <div className="card mb-4">
                            <div className="card-body">
                                <h2 className="mt-3 tittle2 mb-3">Outdoor</h2>
                                <form>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="First name" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Last name" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Phone Number Mobile" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Phone Number Office" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Company Name" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Company Address" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Suit" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="City" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="State" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Zip Code" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Geographical Target Market" group type="text" validate error="wrong"
                                                        success="right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="tittle2">Campaign Details</h3>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Start Date" group type="text" validate error="wrong" success="right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Duration" group type="text" validate error="wrong" success="right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <MDBInput label="Budget" group type="text" validate error="wrong" success="right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="tittle2">Media Format</h3>
                                    <div className="row justify-content-center mt-3">
                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <p className="text-left tittle1">Bill Board</p>
                                            <div className="md-form ml-3 text-center">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="static" />
                                                        <label className="custom-control-label" for="static">Static</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="md-form ml-3">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="degital" />
                                                        <label className="custom-control-label" for="degital">Digital</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4 col-lg-4 tetx-right">
                                            <p className="text-left tittle1">Transit</p>
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="transitstatic" />
                                                        <label className="custom-control-label" for="transitstatic">Static</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="transitdigital" />
                                                        <label className="custom-control-label" for="transitdigital">Digital</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-4 col-lg-4">
                                            <p className="text-left tittle1">Poaster</p>
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="defaultUncheckedDisabled1" />
                                                        <label className="custom-control-label" for="defaultUncheckedDisabled2">Static</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="defaultUncheckedDisabled2" />
                                                        <label className="custom-control-label" for="defaultUncheckedDisabled2">Digital</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mt-3 ml-3">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <h3 className="tittle2 mt-3 mb-3 text-left">Messaging Goals</h3>
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="defaultUncheckedDisabled1" />
                                                        <label className="custom-control-label" for="defaultUncheckedDisabled2">Brand Recognition</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="defaultUncheckedDisabled2" />
                                                        <label className="custom-control-label" for="defaultUncheckedDisabled2">Promote Product/Service</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="defaultUncheckedDisabled2" />
                                                        <label className="custom-control-label" for="defaultUncheckedDisabled2">Advertise Business Location</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <h3 className="tittle2 mt-3 mb-3 text-left">Desired Outcomes</h3>
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="defaultUncheckedDisabled1" />
                                                        <label className="custom-control-label" for="defaultUncheckedDisabled2">Increase Website Visits</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="defaultUncheckedDisabled2" />
                                                        <label className="custom-control-label" for="defaultUncheckedDisabled2">Generate Phone Inquiries</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="defaultUncheckedDisabled2" />
                                                        <label className="custom-control-label" for="defaultUncheckedDisabled2">Increase Product/Service Sales</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="md-form mt-0">
                                                <div className="form-group">
                                                <MDBInput type="textarea" label="Comments"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterComponent></FooterComponent>
            </div>
        );
    }
}

export default NewsPaper;