import React, { Component } from 'react'
import { AuthConsumer } from "../Service/authProvider";

export default class SignOutComponent extends Component {
  
    render() {
        return (
            <AuthConsumer>
                {
                    (p)=>{
                        p.logout();

                        return <div>loading...</div>
                    }
                }
            </AuthConsumer>
        )
    }
}