
import React, { Component } from "react";
import './App.css';
import './Assets/Global.css';
import { MainContent } from "./Components/MainContent"
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Service/authProvider";
import { Routes } from "./Routes/Router";
import AuthService from "./Service/authService";

class App extends Component {

    authService;

    constructor(props) {
        super(props);

        this.authService = new AuthService();

    }


    render() {
        return (
            <div>
                <div>
                    <AuthProvider value={this.authService}>
                        <BrowserRouter children={Routes} basename={"/"} />
                    </AuthProvider>

                </div>
            </div>
        );
    }
}

export default App;
