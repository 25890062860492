import React, { Component } from 'react'
import { AuthConsumer } from "../Service/authProvider";

export default class SilentRenewComponent extends Component {

    render() {
        return (
            <AuthConsumer>
                {
                    (p)=>{
                        p.silentRefresh();

                        return <div>loading...</div>
                    }
                }
            </AuthConsumer>
        )
    }
}