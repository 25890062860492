
import logo from '../Images/MF_full_web.png'
import React, { Component } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse } from "mdbreact";
import { AuthConsumer } from '../Service/authProvider';

export default class HeaderComponent extends Component {

    state = {
        isOpen: false
    };

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            <Router>
                <MDBNavbar style={{ backgroundColor: "white" }} fixed="top" light expand="lg">
                    <MDBNavbarBrand>
                        <img src={logo} height="38" className="d-inline-block"
                            alt="MediaFiche logo" />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler onClick={this.toggleCollapse} />

                    <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                        <MDBNavbarNav right>
                            {/* <MDBNavItem>
                                    <a className="tittle2 pr-3" href="/"><i class="fas fa-home tittle1"></i> Home</a>
                            </MDBNavItem> */}

                            <MDBNavItem>
                                <AuthConsumer>
                                    {
                                        (p) => {
                                            return (
                                                p.isAuthenticated() === true ?
                                                    <span className="tittle2 pr-3"> <i className="fas fa-user tittle1"></i>
                                                        <span className=" o-display-block pl-1 pr-1"> Welcome {p.getUserFullName()}</span>
                                                    </span>
                                                    : ''
                                            )
                                        }
                                    }
                                </AuthConsumer>
                            </MDBNavItem>

                            <MDBNavItem>
                                <AuthConsumer>
                                    {
                                        (p) => {
                                            return (
                                                p.isAuthenticated() === false ?
                                                    <a href="/login" className="tittle2 pr-3"><i className="fa fa-sign-in-alt tittle1"></i> Log In</a>
                                                    : ''
                                            )
                                        }
                                    }
                                </AuthConsumer>
                            </MDBNavItem>

                            <MDBNavItem>
                                <AuthConsumer>
                                    {
                                        (p) => {
                                            return (
                                                p.isAuthenticated() === true ?
                                                    <a href="/logout" className="tittle2 pr-3"><i className="fa fa-sign-in-alt tittle1"></i> Log Out</a>
                                                    : ''
                                            )
                                        }
                                    }
                                </AuthConsumer>
                            </MDBNavItem>

                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBNavbar>
            </Router>
        );
    }
}