import { UserManager, WebStorageStateStore } from "oidc-client";

export default class AuthService {

    _user;

    UserManager;


    constructor() {

        this.UserManager = new UserManager({
            authority: process.env.REACT_APP_IDENTITY_SERVER_BASE_URL,
            client_id: process.env.REACT_APP_CLIENT_ID,
            redirect_uri: `${process.env.REACT_APP_CLIENT_ROOT_URL}/signin-callback`,
            scope: "openid profile roles",
            response_type: "code",
            post_logout_redirect_uri: `${process.env.REACT_APP_CLIENT_ROOT_URL}/signout-callback`,
            automaticSilentRenew: true,
            silent_redirect_uri: `${process.env.REACT_APP_CLIENT_ROOT_URL}/silent-refresh`,
            loadUserInfo: true,
            userStore: new WebStorageStateStore({ store: localStorage }),
            stateStore: new WebStorageStateStore({ store: localStorage }),
        });

        this.UserManager.events.addUserLoaded((user) => {
            this.UserManager.getUser().then((user) => {
                this._user = user;
                console.log("add user loaded");
            })
        });

        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
            this.logout();
        });

        this.UserManager.events.addUserSignedOut(() => {
            this.logout();
            console.log("addUserSignedOut called");
        });
    }


    completeAuthentication = () => {
        return this.UserManager.signinRedirectCallback().then(user => {
            this._user = user;
        });
    }

    login = () => {
        this.UserManager.signinRedirect();
    }

    silentRefresh = () => {
        console.log("silentRefresh called")
        this.UserManager.signinSilentCallback().then((user) => {
            this._user = user;
        });
    };

    logout = () => {
        this.UserManager.clearStaleState().then(() => {
            console.log('cleared');
        });

        this.UserManager.signoutRedirect();
    };

    signout = () => {
        this.UserManager.clearStaleState().then(() => {
            console.log('cleared');
        });

        this.UserManager.signoutRedirectCallback().then(() => {
            this._user = null;
            localStorage.clear();
            window.location.replace('/');
        });
    };

    getUserName = () => {

        const oidcStorage = JSON.parse(localStorage.getItem(`oidc.user:${process.env.REACT_APP_IDENTITY_SERVER_BASE_URL}:${process.env.REACT_APP_CLIENT_ID}`))
        return (!!oidcStorage && oidcStorage.profile.firstName + " " + oidcStorage.profile.lastName);
    }

    getUserFullName = () => {

        const oidcStorage = JSON.parse(localStorage.getItem(`oidc.user:${process.env.REACT_APP_IDENTITY_SERVER_BASE_URL}:${process.env.REACT_APP_CLIENT_ID}`))
        return (!!oidcStorage && oidcStorage.profile.firstName + " " + oidcStorage.profile.lastName);
    }

    isAuthenticated = () => {

        const oidcStorage = JSON.parse(localStorage.getItem(`oidc.user:${process.env.REACT_APP_IDENTITY_SERVER_BASE_URL}:${process.env.REACT_APP_CLIENT_ID}`))
        return (!!oidcStorage && !!oidcStorage.access_token);
    };

    isBuyer = () => {

        const oidcStorage = JSON.parse(localStorage.getItem(`oidc.user:${process.env.REACT_APP_IDENTITY_SERVER_BASE_URL}:${process.env.REACT_APP_CLIENT_ID}`))
        return (!!oidcStorage && oidcStorage.profile.role.indexOf("Buyer") != -1);

    }

    isSeller = () => {

        const oidcStorage = JSON.parse(localStorage.getItem(`oidc.user:${process.env.REACT_APP_IDENTITY_SERVER_BASE_URL}:${process.env.REACT_APP_CLIENT_ID}`))
        return (!!oidcStorage && oidcStorage.profile.role.indexOf("Seller") != -1);

    }
}

