import * as React from "react";
import { Route, Switch } from "react-router-dom";
import SignInCallbackComponent from "../Components/SignInCallbackComponent";
import SignInComponent from "../Components/SignInComponent";
import SignOutCallbackComponent from "../Components/SignOutCallbackComponent";
import SignOutComponent from "../Components/SignOutComponent";
import SilentRenewComponent from "../Components/SilentRenewComponent";
import {PrivateRoute} from './PrivateRouter';
import NewsPaper from '../Components/NewsPaper';
import Dooh from '../Components/Dooh';
import Television from '../Components/Television';
import {MainContent} from '../Components/MainContent';

export const Routes = (
    <Switch>
        <Route exact={true} path="/logout" component={SignOutComponent} />
        <Route exact={true} path="/signout-callback" component={SignOutCallbackComponent} />
        <Route exact={true} path="/silent-refresh" component={SilentRenewComponent} />
        <Route exact={true} path="/signin-callback" component={SignInCallbackComponent} />
        <Route exact={true} path="/login" component={SignInComponent} />
        <Route exact={true} path="/" component={MainContent} />
        <Route path="/newspaper" component={NewsPaper} />
        <Route path="/dooh" component={Dooh} />
        <Route path="/tv" component={Television} />
    </Switch>
);