import React, { Component } from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import { MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";

class Dooh extends Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: "",
            phoneNumberMobile: "",
            phoneNumberOffice: "",
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            st: "",
            zip: "",
            geographicalTargetMarket: "",
            startDate: "",
            duration: "",
            budget: "",
            comments: ""

        }
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        return (
            <div className="container-fluid">
                <HeaderComponent></HeaderComponent>
                <div className="row justify-content-center no-gutters pt-5 mt-3" >
                    <div className="col-sm-9 col-md-9">
                        <p className="mt-3 text-left tittle2">
                            <i className="fas fa-arrow-left"></i>
                            <a href="\" className="tittle2 pl-2">back to home</a>
                        </p>

                        <div className="card mb-4">
                            <div className="card-body">
                                <h2 className="mt-3 tittle2 mb-3 App">Outdoor</h2>
                                <form onSubmit={this.submitHandler} noValidate>

                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="First Name"
                                                type="text"
                                                value={this.state.firstName}
                                                onChange={this.changeHandler}
                                                id="firstName"
                                                name="firstName"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                                <div className="valid-feedback"></div>
                                            </MDBInput>
                                        </MDBCol>

                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Last Name"
                                                type="text"
                                                value={this.state.lastName}
                                                onChange={this.changeHandler}
                                                id="lastName"
                                                name="lastName"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                                <div className="valid-feedback"></div>
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Phone Number (Mobile)"
                                                type="text"
                                                value={this.state.phoneNumberMobile}
                                                onChange={this.changeHandler}
                                                id="phoneNumberMobile"
                                                name="phoneNumberMobile"
                                            >

                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Phone Number (Office)"
                                                type="text"
                                                value={this.state.phoneNumberOffice}
                                                onChange={this.changeHandler}
                                                id="phoneNumberOffice"
                                                name="phoneNumberOffice"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="12">
                                            <MDBInput
                                                label="Company Name"
                                                type="text"
                                                value={this.state.companyName}
                                                onChange={this.changeHandler}
                                                id="companyName"
                                                name="companyName"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="9">
                                            <MDBInput
                                                label="Address Line 1"
                                                type="text"
                                                value={this.state.addressLine1}
                                                onChange={this.changeHandler}
                                                id="addressLine1"
                                                name="addressLine1"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="3">
                                            <MDBInput
                                                label="Suite"
                                                type="text"
                                                value={this.state.addressLine2}
                                                onChange={this.changeHandler}
                                                id="addressLine2"
                                                name="addressLine2"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="City"
                                                type="text"
                                                value={this.state.city}
                                                onChange={this.changeHandler}
                                                id="city"
                                                name="city"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="State"
                                                type="text"
                                                value={this.state.st}
                                                onChange={this.changeHandler}
                                                id="st"
                                                name="st"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="Zip"
                                                type="text"
                                                value={this.state.zip}
                                                onChange={this.changeHandler}
                                                id="zip"
                                                name="zip"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Geographical Target Market"
                                                type="text"
                                                value={this.state.geographicalTargetMarket}
                                                onChange={this.changeHandler}
                                                id="geographicalTargetMarket"
                                                name="geographicalTargetMarket"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <h3 className="mt-3 tittle2 mb-3 App">Campaign Details</h3>


                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Start Date"
                                                type="text"
                                                value={this.state.startDate}
                                                onChange={this.changeHandler}
                                                id="startDate"
                                                name="startDate"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Duration"
                                                type="text"
                                                value={this.state.duration}
                                                onChange={this.changeHandler}
                                                id="duration"
                                                name="duration"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>


                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Budget"
                                                type="text"
                                                value={this.state.budget}
                                                onChange={this.changeHandler}
                                                id="budget"
                                                name="budget"
                                            >
                                            </MDBInput>
                                        </MDBCol>

                                    </MDBRow>


                                    <h3 className="mt-3 tittle2 mb-3 App">Media Format</h3>

                                    <MDBRow>
                                        <MDBCol md="4">
                                            <p className="text-left tittle1">Bill Board</p>
                                            <div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="billBoardStatic" />
                                                        <label className="custom-control-label" htmlFor='billBoardStatic'>Static</label>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="billBoardDigital" />
                                                        <label className="custom-control-label" htmlFor='billBoardDigital'>Digital</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <p className="text-left tittle1">Transit</p>
                                            <div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="transitStatic" />
                                                        <label className="custom-control-label" htmlFor='transitStatic'>Static</label>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="transitDigital" />
                                                        <label className="custom-control-label" htmlFor='transitDigital'>Digital</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <p className="text-left tittle1">Poaster</p>
                                            <div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="poasterStatic" />
                                                        <label className="custom-control-label" htmlFor='poasterStatic'>Static</label>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="poasterDigital" />
                                                        <label className="custom-control-label" htmlFor='poasterDigital'>Digital</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>


                                    <hr />

                                    <MDBRow>
                                        <MDBCol md="6">
                                            <h3 className="tittle2 mt-3 mb-3">Messaging Goals</h3>
                                            <div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="brandRecognition" />
                                                        <label className="custom-control-label" htmlFor='brandRecognition'>Brand Recognition</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="promoteProduct" />
                                                        <label className="custom-control-label" htmlFor='promoteProduct'>Promote Product/Service</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="advertiseBusinessLocation" />
                                                        <label className="custom-control-label" htmlFor='advertiseBusinessLocation'>Advertise Business Location</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <h3 className="tittle2 mt-3 mb-3">Desired Outcomes</h3>
                                            <div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="increaseWebsiteVisits" />
                                                        <label className="custom-control-label" htmlFor='increaseWebsiteVisits'>Increase Website Visits</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="generatePhoneInquiries" />
                                                        <label className="custom-control-label" htmlFor='generatePhoneInquiries'>Generate Phone Inquiries</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="increaseProductServiceSales" />
                                                        <label className="custom-control-label" htmlFor='increaseProductServiceSales'>Increase Product/Serivce Sales</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </MDBCol>
                                    </MDBRow>


                                    <MDBRow>
                                        <MDBCol>
                                            <MDBInput
                                                label="Comments"
                                                type="textarea"
                                                rows="5"
                                                value={this.state.comments}
                                                onChange={this.changeHandler}
                                                id="comments"
                                                name="comments"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow className="justify-content-center">

                                        <a className="card-link mb-4">
                                            <button type="submit" className="btn mf-btn border-radius btn-md waves-light">
                                                Submit
                                            </button>
                                        </a>

                                    </MDBRow>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterComponent></FooterComponent>
            </div>
        );
    }
}


export default Dooh;