import React, { Component } from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';

import { MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";

class Television extends Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: "",
            phoneNumberMobile: "",
            phoneNumberOffice: "",
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            st: "",
            zip: "",
            geographicalTargetMarket: "",
            advertiser: "",
            product: "",
            flightDates: "",
            budget: "",
            demo: "",
            programmingExclusions: "",
            commercialLength: "",
            additionalInformation: ""

        }
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
    };


    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        return (
            <div className="container-fluid">
                <HeaderComponent></HeaderComponent>
                <div className="row justify-content-center no-gutters pt-5 mt-3" >
                    <div className="col-sm-9 col-md-9">
                        <p className="mt-3 text-left tittle2">
                            <i className="fas fa-arrow-left"></i>
                            <a href="\" className="tittle2 pl-2">back to home</a>
                        </p>
                        <div className="card mb-4">
                            <div className="card-body">
                                <h2 className="mt-3 tittle2 mb-3 App">TV - Request For Proposal</h2>
                                <form onSubmit={this.submitHandler} noValidate>
                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="First Name"
                                                type="text"
                                                value={this.state.firstName}
                                                onChange={this.changeHandler}
                                                id="firstName"
                                                name="firstName"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                                <div className="valid-feedback"></div>
                                            </MDBInput>
                                        </MDBCol>

                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Last Name"
                                                type="text"
                                                value={this.state.lastName}
                                                onChange={this.changeHandler}
                                                id="lastName"
                                                name="lastName"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                                <div className="valid-feedback"></div>
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Phone Number (Mobile)"
                                                type="text"
                                                value={this.state.phoneNumberMobile}
                                                onChange={this.changeHandler}
                                                id="phoneNumberMobile"
                                                name="phoneNumberMobile"
                                            >

                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Phone Number (Office)"
                                                type="text"
                                                value={this.state.phoneNumberOffice}
                                                onChange={this.changeHandler}
                                                id="phoneNumberOffice"
                                                name="phoneNumberOffice"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="12">
                                            <MDBInput
                                                label="Company Name"
                                                type="text"
                                                value={this.state.companyName}
                                                onChange={this.changeHandler}
                                                id="companyName"
                                                name="companyName"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="9">
                                            <MDBInput
                                                label="Address Line 1"
                                                type="text"
                                                value={this.state.addressLine1}
                                                onChange={this.changeHandler}
                                                id="addressLine1"
                                                name="addressLine1"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="3">
                                            <MDBInput
                                                label="Suite"
                                                type="text"
                                                value={this.state.addressLine2}
                                                onChange={this.changeHandler}
                                                id="addressLine2"
                                                name="addressLine2"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="City"
                                                type="text"
                                                value={this.state.city}
                                                onChange={this.changeHandler}
                                                id="city"
                                                name="city"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="State"
                                                type="text"
                                                value={this.state.st}
                                                onChange={this.changeHandler}
                                                id="st"
                                                name="st"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="4">
                                            <MDBInput
                                                label="Zip"
                                                type="text"
                                                value={this.state.zip}
                                                onChange={this.changeHandler}
                                                id="zip"
                                                name="zip"
                                                required
                                            >
                                                <div className="invalid-feedback">
                                                    Required
                                                </div>
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Geographical Target Market"
                                                type="text"
                                                value={this.state.geographicalTargetMarket}
                                                onChange={this.changeHandler}
                                                id="geographicalTargetMarket"
                                                name="geographicalTargetMarket"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>


                                    <h3 className="mt-3 tittle2 mb-3 App">Campaign Details</h3>

                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Advertiser"
                                                type="text"
                                                value={this.state.advertiser}
                                                onChange={this.changeHandler}
                                                id="advertiser"
                                                name="advertiser"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Product"
                                                type="text"
                                                value={this.state.product}
                                                onChange={this.changeHandler}
                                                id="product"
                                                name="product"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>


                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Flight Dates"
                                                type="text"
                                                value={this.state.flightDates}
                                                onChange={this.changeHandler}
                                                id="flightDates"
                                                name="flightDates"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Budget"
                                                type="text"
                                                value={this.state.budget}
                                                onChange={this.changeHandler}
                                                id="budget"
                                                name="budget"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Demo"
                                                type="text"
                                                value={this.state.demo}
                                                onChange={this.changeHandler}
                                                id="demo"
                                                name="demo"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                        <MDBCol md="6">
                                            <MDBInput
                                                label="Programming Exclusions"
                                                type="text"
                                                value={this.state.programmingExclusions}
                                                onChange={this.changeHandler}
                                                id="programmingExclusions"
                                                name="programmingExclusions"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>


                                    <h3 className="mt-3 tittle2 mb-3 App">Media Format</h3>


                                    <MDBRow>
                                        <MDBCol md="6" className="justify-content-center">
                                            <MDBInput
                                                label="Commerical Length"
                                                type="text"
                                                value={this.state.commercialLength}
                                                onChange={this.changeHandler}
                                                id="commercialLength"
                                                name="commercialLength"
                                            >
                                            </MDBInput>
                                        </MDBCol>

                                    </MDBRow>

                                    <MDBRow>
                                        <MDBCol md="12">
                                            <MDBInput
                                                label="Additional Information"
                                                type="textarea"
                                                rows="5"
                                                value={this.state.additionalInformation}
                                                onChange={this.changeHandler}
                                                id="additionalInformation"
                                                name="additionalInformation"
                                            >
                                            </MDBInput>
                                        </MDBCol>
                                    </MDBRow>

                                    <MDBRow className='justify-content-center'>
                                        <a className="card-link mb-4">
                                            <button type="submit" className="btn mf-btn border-radius btn-md waves-light">
                                                Submit
                                            </button>
                                        </a>
                                    </MDBRow>

                                </form>
                            </div>


                        </div>


                    </div>
                </div>
                <FooterComponent></FooterComponent>
            </div>
        );
    }
}
export default Television;